<template>
  <div class="all_main_wrap">
    <!-- 头部 -->
    <div class="all_header_box">
      <div class="sider_left">
        <span class="title">{{ !$isExamSearch()?"考试管理":"考试查询" }}</span>
        <!-- 面包屑 -->
        <div class="crumbs">
          <span class="crumbs_item" @click="goBack">{{$isExamSearch()?'考生详情':'报名审核'}} / </span>
          <span class="crumbs_item crumbs_last">报名信息</span>
        </div>
      </div>
    </div>
    <div class="content-box">
      <div class="info">
        <div class="Personal">
          <span class="name">个人证件照</span>
          <!-- <viewer v-if="operation==1"> 
            <img  class="person-image" :src="form.certifiMobile" title="" alt="">
          </viewer> -->
          <a-upload
            name="certifiy"
            style="margin-top:10px"
            list-type="picture-card"
            accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
            :showUploadList="false"
            :loading="loadingRound"
            :beforeUpload="beforeUpload"
            :customRequest="customRequest"
          >
            <img v-if="form.certifiMobile" style="margin-top:0" class="person-image" :src="form.certifiMobile" title="" alt="">
            <div v-else>
              <a-icon :type="loadingRound ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传图片
              </div>
            </div>
          </a-upload>
          
        </div>
        <div class="side-r">
          <a-row class="a-row-item">
            <a-col class="left_inner" :span="12">
              <span class="all_left_name">姓名：</span>
              <a-input class="right" v-model="form.name" :disabled="true" />
            </a-col>
            <a-col class="left_inner" :span="12">
              <span class="all_left_name">性别：</span>
              <a-radio-group name="radioGroup" :default-value="1" v-model="form.sex">
                <a-radio :value="1">男</a-radio>
                <a-radio :value="0">女</a-radio>
              </a-radio-group>
            </a-col>
          </a-row>
          <a-row class="a-row-item">
            <a-col class="left_inner" :span="12">
              <span class="all_left_name" >学历：</span>
              <a-input v-model="form.education" class="right"  />
            </a-col>
            <a-col class="left_inner" :span="12">
              <span class="all_left_name" >工作单位：</span>
              <a-input v-model="form.workUnit" class="right"  />
            </a-col>
          </a-row>
          <a-row class="a-row-item">
            <a-col class="left_inner" :span="12">
              <span class="all_left_name">出生日期：</span>
              <a-date-picker v-model="form.birthday" format="YYYY-MM-DD" class="right"  />
            </a-col>
            <a-col class="left_inner" :span="12">
              <span class="all_left_name">职务/职称：</span>
              <a-input v-model="form.post" class="right"  />
            </a-col>
          </a-row>
          <a-row class="a-row-item">
            <a-col class="left_inner" :span="12">
              <span class="all_left_name">手机号：</span>
              <a-input :value="form.mobile" class="right" :disabled="true" />
            </a-col>
            <a-col class="left_inner" :span="12">
              <span class="all_left_name">身份证号：</span>
              <a-input :value="form.idcard" class="right" :disabled="true" />
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="id_card">
        <h3>身份认证</h3>
        <div class="flex">
          <div class="id_card_item">
            <!-- <viewer v-if="operation==1">
              <img class="img" title="" :src="form.frontPicture" alt="">
            </viewer> -->
            <a-upload
              name="front"
              list-type="picture-card"
              :showUploadList="false"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :loading="loadingFront"
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <img v-if="form.frontPicture" class="img" :src="form.frontPicture" title="" alt="">
              <div v-else>
                <a-icon :type="loadingFront ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
            <span class="tips">身份证反面照片</span>
          </div>
          <div class="id_card_item">
            <!-- <viewer v-if="operation==1">
              <img class="img" title="" :src="form.reversePicture" alt="">
            </viewer> -->
            <a-upload
              name="reverse"
              list-type="picture-card"
              :showUploadList="false"
              :loading="loadingReverse"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <img v-if="form.reversePicture" class="img" :src="form.reversePicture" title="" alt="">
              <div v-else>
                <a-icon :type="loadingReverse ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
            <span class="tips">身份证正面照片</span>
          </div>
          <div class="id_card_item">
            <!-- <viewer v-if="operation==1">
              <img class="img" title="" :src="form.heldPhotos" alt="">
            </viewer> -->
            <a-upload
              name="held"
              list-type="picture-card"
              :showUploadList="false"
              :loading="loadingHeld"
              accept=".jpg,.png,.jpeg,.JPG,.PNG,.JPEG" 
              :beforeUpload="beforeUpload"
              :customRequest="customRequest"
            >
              <img v-if="form.heldPhotos" class="img" :src="form.heldPhotos" title="" alt="">
              <div v-else>
                <a-icon :type="loadingHeld ? 'loading' : 'plus'" />
                <div class="ant-upload-text">
                  上传图片
                </div>
              </div>
            </a-upload>
            <span class="tips">手持身份证照片</span>
          </div>
        </div>
      </div>
      <div class="Reject">
        <span>报名来源：{{form.orgName}}</span>
      </div>
      <div class="Reject" v-if="form.auditStatus == 3">
        <span>驳回原因：</span>
        <a-textarea  class="textarea" v-model="form.rejecReason" :disabled="true" placeholder="驳回原因" :rows="4" />
      </div>
      <div class="foot_btn">
        <a-button type="primary" v-if="!$isExamSearch()" class="btn" :loading="examineLoading" @click="isNumberShow = true" v-hasPermi="['exam:signup:updatestatus']">审核</a-button>
        <a-button type="primary" v-if="!$isExamSearch()" class="btn" :loading="loading" @click="onFormSave" v-hasPermi="['exam:signup:update']">保存</a-button>
        <a-button  class="all_boder_btn" @click="goBack()">返回</a-button>
      </div>
    </div>
    <!-- 审核 -->
    <a-modal v-model="isNumberShow" title="审核" >
      <div class="all_content_box">
        <a-row class="a-row-item">
          <a-col :span="5" class="a-row-left"><span class="name">审核操作：</span></a-col>
          <a-col>
            <a-radio-group v-model="examineValue">
              <a-radio style="width:80px" :value="2">通过</a-radio>
              <a-radio :value="3">驳回</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row class="a-row-item" v-show="examineValue == 2" v-if="false">
          <a-col :span="5" class="a-row-left"><span class="name">是否发送短信：</span></a-col>
          <a-col>
            <a-radio-group v-model="messageValue">
              <a-radio style="width:80px" :value="1">否</a-radio>
              <a-radio :value="2">是</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <a-row class="a-row-item" v-show="examineValue==3">
          <a-col :span="5" class="a-row-left"><span class="name">驳回原因：</span></a-col>
          <a-col>
            <a-textarea
              v-model="rejecReason"
              style="width:70%"
              placeholder="请输入驳回原因，将在用户终端显示。"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-col>
        </a-row>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="examineLoading" @click="onExamine">确认</a-button>
        <a-button  @click="isNumberShow=false">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      rejecReason: '', //驳回原因
      isNumberShow: false,
      examineValue:2,
      messageValue:2,
      operation:'',
      loading:false,
      examineLoading:false,
      form:{
        signupUserId:'',
        birthday: moment(new Date(), 'YYYY-MM-DD')
      },

      loadingRound: false,
      loadingFront: false,
      loadingReverse: false,
      loadingHeld: false,
    }
  },
  // 事件处理器
  methods: {
    moment,
    // 返回上一页
    goBack(){
      this.$router.back()
      // this.$router.push({ path: "/admin/Examination/RegistrationReview",query: { signupId:this.$route.query.signupId, timeId:this.$route.query.timeId  } })
    },
    // 上传前钩子
    beforeUpload(file){
      // return this.upload.getToKen()
       if (!this.$regular.imgReg.reg.test(file.name)) {
        this.$message.warning(this.$regular.imgReg.msg);
        return false;
      }
      // return this.upload.getToKen()
      const isJpgOrPng =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJpgOrPng) {
        this.$message.error("请上传正确格式图片文件!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("图片内存大小需在2MB之内");
      }
      return isJpgOrPng && isLt2M;
    },

    // 文件上传 - 个人照片
    customRequest(fileData){
      const name = fileData.filename // 名称前缀
      if(name == 'certifiy'){
        this.loadingRound = true
      } else if (name == 'front'){
        this.loadingFront = true
      } else if (name == 'reverse'){
        this.loadingReverse = true
      } else if (name == 'held'){
        this.loadingHeld = true
      }
      const bucket = 'user/signup/' // *必填 文件存储地址（地址参考cosUpload.js说明文件）
      // 腾讯云对象存储上传文件
      this.$cosUpload(fileData.file,bucket,this.fileProgressCallback,(url, fileName) => {
        // 上传完成
        if (url) {
          if(name == 'certifiy'){
            this.form.certifiMobile = url
          } else if (name == 'front'){
            this.form.frontPicture = url
          } else if (name == 'reverse'){
            this.form.reversePicture = url
          } else if (name == 'held'){
            this.form.heldPhotos = url
          }
        } else {
          this.$message.error("上传失败");
        }
        this.loadingRound = false;
        this.loadingFront = false;
        this.loadingReverse = false;
        this.loadingHeld = false;
      });
    },

    // 文件上传更新进度和单文件上传初始化
    fileProgressCallback(progress, speed, name) {
      /*
       * progress 进度
       * speed 传输速度
       * name 文件名称
       * */
    },

    // 保存列表
    onFormSave(){
      this.loading = true
      this.$ajax({
        method: 'put',
        url: '/hxclass-management/exam/signup/signup/user/edit',
        params: this.form
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
        } else{
          this.$message.warning(res.message);
        }
      })
    },
    // 获取详情
    getFormDetai(){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/exam/signup/signup/user/get",
        params:{
          signupUserId:this.form.signupUserId
        }
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.form = res.data
          this.form.sex = res.data.sex ? 1 : 0
        }
      })
    },
    // 审核
    onExamine(){
      if(this.form.studyStatus != 3 && this.examineValue==2){
        this.$message.warning('未学完，不可审核通过');
        return
      }
      if(this.form.auditStatus == 2 && this.examineValue==2){
        this.$message.warning('学员已审核通过，无需再次审核');
        return
      }
      this.examineLoading = true
      this.$ajax({
        method: 'put',
        url: '/hxclass-management/exam/signup/user/audit?auditStatus=' + this.examineValue + '&signupUserId=' + this.form.signupUserId + '&rejecReason=' +this.rejecReason + '&examineeUserId=' +this.form.userId,
      }).then(res => {
        this.examineLoading = false
        if (res.code == 200 && res.success) {
          this.goBack()
          this.$message.success(res.message);
        } else{
          this.$message.warning(res.message);
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.operation = this.$route.query.operation
    this.form.signupUserId = this.$route.query.id
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getFormDetai()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.content-box{
  padding: 30px 40px;
  .name{
    font-weight: 400;
    color: #000000;
  }
  .info{
    display: flex;
    .Personal{
      margin-right: 100px;
      width: 122px;
      .person-image{
        margin-top: 10px;
        width: 140px;
        height: 160px;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      /deep/ .ant-upload.ant-upload-select-picture-card {
        width: 140px;
        height: 160px;
      }
    }
    .side-r{
      min-width: 900px;
       .a-row-item{
        margin-bottom: 20px;
        .all_left_name{
          line-height: 32px;
          min-width: 90px;
          text-align: right;
        }
        .left_inner{
          display: flex;
          align-items: center;
        }
        .number{
          font-size: 12px;
          line-height: 32px;
          color: #2B6ED4;
          cursor: pointer;
        }
        .right{
          width: 60%;
        }
        .tips{
          margin-top: 5px;
          font-size: 12px;
          color: #FF3838;
        }
      }
    }
  }
  .id_card{
    margin-top: 80px;
    h3{
      font-size: 18px;
      color: #333;
      font-weight: 500;
      margin-bottom: 10px;
    }
    .flex{
      max-width: 1000px;
      display: flex;
      justify-content: space-between;
    }
    .id_card_item{
      text-align: center;
      width: 240px;
      /deep/ .ant-upload.ant-upload-select-picture-card {
        width: 240px;
        height: 150px;
      }
      .img{
        width: 100%;
        height: 150px;
        background-repeat: no-repeat;
        object-fit: cover;
      }
      .tips{
        display: inline-block;
        margin-top: 10px;
        font-size: 14px;
      }
    }
  }
}

.all_content_box{
  .a-row-item{
    margin-bottom: 20px;
    .a-row-left{
      margin-right: 10px;
    }
    .name{
      width: 100%;
      display: inline-block;
      text-align: right;
    }
    .right{
      width: 100%;
    }
  }
}

.Reject{
  margin-top: 20px;
  span{
    display: inline-block;
    margin-bottom: 10px;
  }
}

.foot_btn{
  margin-top: 70px;
  .btn{
    margin-right: 20px;
  }
}
</style>
